<template>
  <div class="meadia" style="cursor:pointer">
    <!--   header 组件     -->
    <header_public></header_public>
    <!--    大标题部分    -->
    <div class="maska" v-if="videoState == true" @click="masksCloseFun()"></div>
    <div class="videomasks" v-if="videoState == true">
      <video
        :src="videoSrc"
        controls="controls"
        autoplay
        style="width: 57vw;height:32vw;"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
    <div v-else-if="imgState">
      <div class="maska" @click="masksCloseFun()"></div>
      <div class="imagemasks">
        <img :src="imgSrc" controls="controls" autoplay class="show-img" />
      </div>
    </div>
    <div class="meadia-top">
      <div
        class="meadia-top-con flex_style direction_column flex_nowrap justify_center"
      >
        <ul class="flex_style direction_column flex_nowrap align_flex-start">
          <li>
            <h1 class="text_family">视觉设计学院</h1>
          </li>
          <li>
            <p class="text_family">
              旨在培养人机交互、用户体验、界面设计方面具有高层次艺术、技术
              知识交叉复合型的数字媒体艺术设计人才的专业摇篮
            </p>
          </li>
          <li class="flex_style direction_row flex_nowrap">
            <div
              style="border:2px solid rgba(226,226,226,1);border-radius:3px;"
              class="flex_style direction_row flex_nowrap align_center"
              @click="
                checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')
              "
            >
              <img src="../../assets/images/banner-bf.png" alt />
              <span style="color: #000000!important;">高薪之旅</span>
            </div>
          </li>
          <li>
            <span class="text_family"
              >新数字媒体艺术，全栈UIUE来积云就够了</span
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- 效率部分 -->
    <div class="use-time con_style">
      <div class="con_title text_left">
        <!--                <span class="text_family">专业素养、行业精英</span>-->
        <h4 class="text_family text_color">
          互联网全链路设计
          <br />UIUE 核心能力值
        </h4>
        <img src="../../assets/images/btzst.png" alt />
      </div>
      <div class="features">
        <ul
          class="flex_style direction_row flex_nowrap justify_between text_left"
        >
          <li class="flex_style direction_row flex_nowrap">
            <img src="../../assets/images/tztb.png" alt />
            <div class="text_family">
              <span>精湛的视觉表现能力</span>
              <p>从传统到互联网移动时代牢牢锁住用户眼球。</p>
            </div>
          </li>
          <li class="flex_style direction_row flex_nowrap">
            <img src="../../assets/images/tztb.png" alt />
            <div class="text_family">
              <span>超出用户预期的体验设计能力</span>
              <p>从用户习惯到使用场景从需求到满足精准完美</p>
            </div>
          </li>
          <li class="flex_style direction_row flex_nowrap">
            <img src="../../assets/images/tztb.png" alt />
            <div class="text_family">
              <span>PM般的产品塑造思维能力</span>
              <p>需求的完美捕捉功能的极致打磨一步到位</p>
            </div>
          </li>
        </ul>
      </div>

      <div style="position: relative">
        <!-- 轮播图 -->
        <div
          class="bannerWrap"
          style="width: 90%;margin: 4.166vw auto 5.208vw;"
        >
          <!-- style="width:100%;height:300px;"-->
          <!-- Swiper -->
          <div class="swiper-container value-swiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div
                  class="video_box"
                  @click="
                    checkVideoFun(majorvideo[0] ? majorvideo[0]['image'] : '')
                  "
                >
                  <img
                    :src="majorvideo[0] ? majorvideo[0]['imageThumb'] : ''"
                    alt
                  />
                  <div>
                    <img src="../../assets/images/redbf.png" alt />
                  </div>
                </div>
                <div
                  class="video_text flex_style direction_column justify_around"
                >
                  <span>设计师的核心价值体现</span>
                  <p>
                    从用户体验的角度出发去完成作品,从底层战略出发,思考需求背后的价值,而不是以单点式的视角看待事物和问题。
                  </p>
                </div>
              </div>
            </div>
            <!-- Add Pagination -->
          </div>
          <!-- Add Arrows -->
          <!--                <div class="swiper-button-next swiper-button-white value-button-next"></div>-->
          <!--                <div class="swiper-button-prev swiper-button-white value-button-prev"></div>-->
        </div>
      </div>

      <div class="con_title">
        <!-- <span class="text_family">选择改变未来，专业成就人生</span> -->
        <h4 class="text_family text_color">
          视觉设计影音档案
          <br />送给青春最好的礼物
        </h4>
        <img src="../../assets/images/btzst.png" alt />
      </div>
      <div class="movies_video flex_style justify_between">
        <div class="movies_videoLe">
          <img
            :src="videolistinfo[0] ? videolistinfo[0]['imageThumb'] : ''"
            alt
            @click="
              checkVideoFun(
                videolistinfo[0] ? videolistinfo[0]['imageThumb'] : ''
              )
            "
          />
          <div class="anniu">
            <img src="../../assets/images/bfbig_03.png" alt />
          </div>
          <div
            class="movies_decorate flex_style direction_column flex_nowrap justify_center align_flex-start"
          >
            <span>{{
              videolistinfo[0] ? videolistinfo[0]["extend"]["subtitle"] : ""
            }}</span>
            <h4>{{ videolistinfo[0] ? videolistinfo[0]["title"] : "" }}</h4>
            <!--                        <p>2020年5.21</p>-->
          </div>
        </div>
        <div
          class="movies_videoRi flex_style direction_column flex_nowrap justify_between"
        >
          <div class="movies_videoRiTo">
            <img
              :src="videolistinfo[1] ? videolistinfo[1]['imageThumb'] : ''"
              alt
              @click="
                checkVideoFun(videolistinfo[1] ? videolistinfo[1]['image'] : '')
              "
            />
            <div class="anniu_small">
              <img src="../../assets/images/bfbig_03.png" alt />
            </div>
            <div class="movies_decorateSmall flex_public">
              <span>{{
                videolistinfo[1] ? videolistinfo[1]["extend"]["subtitle"] : ""
              }}</span>
              <h4>{{ videolistinfo[1] ? videolistinfo[1]["title"] : "" }}</h4>
            </div>
          </div>
          <div class="movies_videoRiBo">
            <img
              :src="videolistinfo[2] ? videolistinfo[2]['imageThumb'] : ''"
              alt
            />
            <div class="anniu_smallBo">
              <img src="../../assets/images/bfbig_03.png" alt />
            </div>
            <div class="movies_decorateSmall flex_public">
              <span>{{
                videolistinfo[2] ? videolistinfo[2]["extend"]["subtitle"] : ""
              }}</span>
              <h4>{{ videolistinfo[2] ? videolistinfo[2]["title"] : "" }}</h4>
            </div>
          </div>
        </div>
      </div>
      <div class="con_title">
        <!-- <span class="text_family">全案能力，成就职场</span> -->
        <h4 class="text_family text_color">
          为企业提供高效的全案解决
          <br />方案人才能力
        </h4>
      </div>
      <div class="scheme flex_style direction_row flex_nowrap justify_between">
        <ul class="flex_style direction_column flex_nowrap justify_center">
          <li class="flex_style direction_row flex_nowrap justify_end">
            <div class="text_family">
              <span>产品原型设计</span>
              <p>需求和功能的具象化表达，所以原型可以辅助产品经理与领导。</p>
            </div>
            <img class="margin-left" src="../../assets/images/gxdg.png" alt />
          </li>
          <li class="flex_style direction_row flex_nowrap justify_end">
            <div class="text_family">
              <span>品牌视觉创意</span>
              <p>以策略指引创意表达，致力于通过创新 的价值为企业服务。</p>
            </div>
            <img class="margin-left" src="../../assets/images/gxdg.png" alt />
          </li>
          <li class="flex_style direction_row flex_nowrap justify_end">
            <div class="text_family">
              <span>用户体验设计</span>
              <p>善于深入了解用户需求,结合行业经验构 建切实有效的互联网产品</p>
            </div>
            <img class="margin-left" src="../../assets/images/gxdg.png" alt />
          </li>
        </ul>
        <ul
          class="text_left flex_style direction_column flex_nowrap justify_center"
        >
          <li class="flex_style direction_row flex_nowrap justify_end">
            <img class="margin-right" src="../../assets/images/gxdg.png" alt />
            <div class="text_family">
              <span>三维商业视觉表现</span>
              <p>对C4D三维软件的精进研究以达到 更强更高的视觉水准</p>
            </div>
          </li>
          <li class="flex_style direction_row flex_nowrap justify_end">
            <img class="margin-right" src="../../assets/images/gxdg.png" alt />
            <div class="text_family">
              <span>商业插画全案呈现</span>
              <p>提升设计师对物体形态、光影视觉 形成自己独特的原创能力</p>
            </div>
          </li>
          <li class="flex_style direction_row flex_nowrap justify_end">
            <img class="margin-right" src="../../assets/images/gxdg.png" alt />
            <div class="text_family">
              <span>产品运营设计</span>
              <p>从产品本身到后期运营全方位为企业 提供解决方案</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="con_title">
        <!-- <span class="text_family">跨平台设计、全方位呈现</span> -->
        <h4 class="text_family text_color">
          优秀跨平台设计能力
          <br />优势呈现
        </h4>
        <img src="../../assets/images/btzst.png" alt />
      </div>
      <div class="code_quality">
        <ul class="flex_style direction_row flex_nowrap justify_between">
          <li class="flex_style direction_column flex_nowrap align_center">
            <img src="../../assets/images/xlsjdmc.png" alt />
            <span class="text_color text_family">完美的跨平台设计</span>
            <p>PC时代到移动华丽转身。</p>
          </li>
          <li class="flex_style direction_column flex_nowrap align_center">
            <img src="../../assets/images/xlkfdm.png" alt />
            <span class="text_color text_family">基于框架标准的组件设计</span>
            <p>高效的开发流程基于开发框架的设计。</p>
          </li>
          <li class="flex_style direction_column flex_nowrap align_center">
            <img src="../../assets/images/xldzkf.png" alt />
            <span class="text_color text_family">定制高效快速扩展</span>
            <p>强大设计组件库快速应用。</p>
          </li>
        </ul>
      </div>
      <!--            <div class="offer">-->
      <!--                <div class="offer_title  flex_style direction_row flex_nowrap justify_between">-->
      <!--                    <p class="text_color">我们的<br />Offer</p>-->
      <!--                    &lt;!&ndash; 轮播图 &ndash;&gt;-->
      <!--                    <div class="bannerWrap offer_swiper flex_style direction_row flex_nowrap justify_center"> &lt;!&ndash; style="width:100%;height:300px;"&ndash;&gt;-->
      <!--                        &lt;!&ndash; Swiper &ndash;&gt;-->
      <!--                        <div class="swiper-container offer_swiper">-->
      <!--                            <div class="swiper-wrapper">-->
      <!--                                <div class="swiper-slide offer_swiperCon">-->
      <!--                                    <ul class="flex_style direction_row flex_nowrap justify_around">-->
      <!--                                        <li class="flex_style direction_column flex_nowrap justify_center">-->
      <!--                                            <i class="text_family">1月</i>-->
      <!--                                            <span class="text_color">18</span>-->
      <!--                                        </li>-->
      <!--                                        <li class="flex_style direction_column flex_nowrap justify_center">-->
      <!--                                            <i class="text_family">1月</i>-->
      <!--                                            <span class="text_color">03</span>-->
      <!--                                        </li>-->
      <!--                                        <li class="flex_style direction_column flex_nowrap justify_center">-->
      <!--                                            <i class="text_family">1月</i>-->
      <!--                                            <span class="text_color">10</span>-->
      <!--                                        </li>-->
      <!--                                        <li class="flex_style direction_column flex_nowrap justify_center">-->
      <!--                                            <i class="text_family">1月</i>-->
      <!--                                            <span class="text_color">45</span>-->
      <!--                                        </li>-->
      <!--                                        <li class="flex_style direction_column flex_nowrap justify_center">-->
      <!--                                            <i class="text_family">1月</i>-->
      <!--                                            <span class="text_color offer_color">12</span>-->
      <!--                                        </li>-->
      <!--                                    </ul>-->
      <!--                                </div>-->
      <!--                            </div>-->
      <!--                            &lt;!&ndash; Add Pagination &ndash;&gt;-->
      <!--                            &lt;!&ndash; Add Arrows &ndash;&gt;-->
      <!--                            <div class="swiper-button-next swiper-button-white"></div>-->
      <!--                            <div class="swiper-button-prev swiper-button-white"></div>-->
      <!--                        </div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                <div class="offer_con">-->
      <!--                    <ul class="flex_style direction_row flex_nowrap justify_between">-->
      <!--                        <li class="width_33 flex_style direction_row flex_nowrap">-->
      <!--                            <div class="ri_number">-->
      <!--                                <span class="offer_color">12</span>-->
      <!--                                <i class="offer_color">K</i>-->
      <!--                            </div>-->
      <!--                            <div class="le_text text_left">-->
      <!--                                <span class="text_color">吴方言</span>-->
      <!--                                <p>适用于任何媒体的设计解决方案。 很简单的。</p>-->
      <!--                                <ul>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>餐补、交通补助</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>五险一金</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>公司提供宿舍</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>一周双休或者三休</p>-->
      <!--                                    </li>-->
      <!--                                </ul>-->
      <!--                            </div>-->
      <!--                        </li>-->
      <!--                        <li class="width_33 flex_style direction_row flex_nowrap">-->
      <!--                            <div class="ri_number">-->
      <!--                                <span class="offer_color">12</span>-->
      <!--                                <i class="offer_color">K</i>-->
      <!--                            </div>-->
      <!--                            <div class="le_text text_left">-->
      <!--                                <span class="text_color">吴方言</span>-->
      <!--                                <p>适用于任何媒体的设计解决方案。 很简单的。</p>-->
      <!--                                <ul>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>餐补、交通补助</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>五险一金</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>公司提供宿舍</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>一周双休或者三休</p>-->
      <!--                                    </li>-->
      <!--                                </ul>-->
      <!--                            </div>-->
      <!--                        </li>-->
      <!--                        <li class="width_33 flex_style direction_row flex_nowrap">-->
      <!--                            <div class="ri_number">-->
      <!--                                <span class="offer_color">12</span>-->
      <!--                                <i class="offer_color">K</i>-->
      <!--                            </div>-->
      <!--                            <div class="le_text text_left">-->
      <!--                                <span class="text_color">吴方言</span>-->
      <!--                                <p>适用于任何媒体的设计解决方案。 很简单的。</p>-->
      <!--                                <ul>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>餐补、交通补助</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>五险一金</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>公司提供宿舍</p>-->
      <!--                                    </li>-->
      <!--                                    <li class="flex_style direction_row flex_nowrap align_center">-->
      <!--                                        <img src="../../assets/images/dgg_03.png" alt="">-->
      <!--                                        <p>一周双休或者三休</p>-->
      <!--                                    </li>-->
      <!--                                </ul>-->
      <!--                            </div>-->
      <!--                        </li>-->
      <!--                    </ul>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <!-- 解决方案  -->
    <!--        <div class="load_animation flex_style direction_row flex_nowrap justify_end align_center">-->
    <!--            <div class="progress text_left">-->
    <!--                <p class="text_color text_family">正确的解决方案</p>-->
    <!--                <ul>-->
    <!--                    <li>-->
    <!--                        <i class="text_family">发展历程</i>-->
    <!--                        <div class="progress_box">-->
    <!--                            <div><p class="text_family">63%</p></div>-->
    <!--                        </div>-->
    <!--                    </li>-->
    <!--                    <li>-->
    <!--                        <i class="text_family">UI / UX设计</i>-->
    <!--                        <div class="progress_box">-->
    <!--                            <div class="color_orange"><p class="text_family">94%</p></div>-->
    <!--                        </div>-->
    <!--                    </li>-->
    <!--                    <li>-->
    <!--                        <i class="text_family">兼容性</i>-->
    <!--                        <div class="progress_box">-->
    <!--                            <div class="dark_blue"><p class="text_family">82%</p></div>-->
    <!--                        </div>-->
    <!--                    </li>-->
    <!--                </ul>-->
    <!--            </div>-->
    <!--        </div>-->

    <div class="campus_ambientCon">
      <div class="ambientCon_top flex_style justify_between">
        <div
          class="ambientCon_topTex flex_style direction_column flex_nowrap justify_center"
        >
          <h5 class="text_color text_family">我们的学习生活</h5>
          <p>我们的选择，我们的青春，留在积云的美好回忆</p>
        </div>
        <div class="ambientCon_topImg enlarge_img">
          <img
            :src="
              schoolScience[0]
                ? schoolScience[0]['imageThumb']
                  ? schoolScience[0]['imageThumb']
                  : schoolScience[0]['image']
                : ''
            "
            alt
            @click="
              checkimgFun(schoolScience[0] ? schoolScience[0]['image'] : '')
            "
          />
          <div>{{ schoolScience[0] ? schoolScience[0]["title"] : "" }}</div>
        </div>
        <div
          class="ambientCon_topImgCon flex_style direction_column flex_nowrap justify_between"
        >
          <div class="topImgCon_top flex_style justify_between">
            <div class="topImgCon_topLe enlarge_img">
              <img
                :src="
                  schoolScience[1]
                    ? schoolScience[1]['imageThumb']
                      ? schoolScience[1]['imageThumb']
                      : schoolScience[1]['image']
                    : ''
                "
                alt
                @click="
                  checkimgFun(schoolScience[1] ? schoolScience[1]['image'] : '')
                "
              />
              <div>{{ schoolScience[1] ? schoolScience[1]["title"] : "" }}</div>
            </div>
            <div class="topImgCon_topRi enlarge_img">
              <img
                :src="
                  schoolScience[2]
                    ? schoolScience[2]['imageThumb']
                      ? schoolScience[2]['imageThumb']
                      : schoolScience[2]['image']
                    : ''
                "
                alt
                @click="
                  checkimgFun(schoolScience[2] ? schoolScience[2]['image'] : '')
                "
              />
              <div>{{ schoolScience[2] ? schoolScience[2]["title"] : "" }}</div>
            </div>
          </div>
          <div class="topImgCon_bot enlarge_img">
            <img
              :src="
                schoolScience[3]
                  ? schoolScience[3]['imageThumb']
                    ? schoolScience[3]['imageThumb']
                    : schoolScience[3]['image']
                  : ''
              "
              alt
              @click="
                checkimgFun(schoolScience[3] ? schoolScience[3]['image'] : '')
              "
            />
            <p>{{ schoolScience[3] ? schoolScience[3]["title"] : "" }}</p>
          </div>
        </div>
      </div>
      <div class="ambientCon_top flex_style justify_between">
        <div class="ambientCon_topImg enlarge_img">
          <img
            :src="
              schoolScience[4]
                ? schoolScience[4]['imageThumb']
                  ? schoolScience[4]['imageThumb']
                  : schoolScience[4]['image']
                : ''
            "
            alt
            @click="
              checkimgFun(schoolScience[4] ? schoolScience[4]['image'] : '')
            "
          />
          <div>{{ schoolScience[4] ? schoolScience[4]["title"] : "" }}</div>
        </div>
        <div
          class="ambientCon_topImgCon flex_style direction_column flex_nowrap justify_between"
        >
          <div class="topImgCon_top flex_style justify_between">
            <div class="topImgCon_topLe enlarge_img">
              <img
                :src="
                  schoolScience[5]
                    ? schoolScience[5]['imageThumb']
                      ? schoolScience[5]['imageThumb']
                      : schoolScience[5]['image']
                    : ''
                "
                alt
                @click="
                  checkimgFun(schoolScience[5] ? schoolScience[5]['image'] : '')
                "
              />
              <div>{{ schoolScience[5] ? schoolScience[5]["title"] : "" }}</div>
            </div>
            <div class="topImgCon_topRi enlarge_img">
              <img
                :src="
                  schoolScience[6]
                    ? schoolScience[6]['imageThumb']
                      ? schoolScience[6]['imageThumb']
                      : schoolScience[6]['image']
                    : ''
                "
                alt
                @click="
                  checkimgFun(schoolScience[6] ? schoolScience[6]['image'] : '')
                "
              />
              <div>{{ schoolScience[6] ? schoolScience[6]["title"] : "" }}</div>
            </div>
          </div>
          <div class="topImgCon_bot enlarge_img">
            <img
              :src="
                schoolScience[7]
                  ? schoolScience[7]['imageThumb']
                    ? schoolScience[7]['imageThumb']
                    : schoolScience[7]['image']
                  : ''
              "
              alt
              @click="
                checkimgFun(schoolScience[7] ? schoolScience[7]['image'] : '')
              "
            />
            <p>{{ schoolScience[7] ? schoolScience[7]["title"] : "" }}</p>
          </div>
        </div>
        <div
          class="ambientCon_topTex flex_style direction_column flex_nowrap justify_center"
          style="box-sizing: border-box; padding: 0 50px;"
        >
          <h5 class="text_color text_family">快乐成长</h5>
          <p style="width: 100%">
            每一天起床呼吸着校园的新鲜空气对未来充满了美好的想象
          </p>
        </div>
      </div>
    </div>
    <!-- 教师团队 -->
    <div class="con_title">
      <!-- <span class="text_family">专业素养、行业精英</span> -->
      <h4 class="text_family text_color">骨干讲师介绍</h4>
      <img src="../../assets/images/btzst.png" alt />
    </div>
    <div class="bottom_text">
      <!-- 轮播图 -->
      <div class="bannerWrap">
        <!--style="width:100%;height:300px;" -->
        <!-- Swiper -->
        <div
          class="swiper-container teacher-con"
          @mousemove="stop(swiperTeacher)"
          @mouseleave="start(swiperTeacher)"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide teachers_box"
              v-for="(teacher, index) in teacherlist"
              :key="index"
            >
              <a href="javascript:void(0)">
                <img
                  :src="teacher.imageThumb ? teacher.imageThumb : teacher.image"
                  alt
                />
              </a>
              <div
                class="teacher_team_con flex_style direction_column flex_nowrap justify_between"
              >
                <!--                                <div-->
                <!--                                        class="team_con_top flex_style direction_row flex_nowrap justify_between align_center"-->
                <!--                                >-->
                <!--                                    <span class="text_family"></span>-->
                <!--                                    <img src="../../assets/images/quanping.png" alt />-->
                <!--                                </div>-->
                <div class="team_con_bottom text_left">
                  <p class="text_family">{{ teacher.title }}</p>
                  <span class="text_family">{{
                    teacher.extend ? teacher.extend.subtitle : ""
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Add Pagination -->
          <div
            class="swiper-pagination swiper-pagination-white major-spit"
            id="swiper-spit"
          ></div>
        </div>
      </div>
      <!-- Add Arrows -->
      <!--            <div class="swiper-button-next swiper-button-white teachers-switch-next"></div>-->
      <!--            <div class="swiper-button-prev swiper-button-white teachers-switch-prev"></div>-->
    </div>
    <div class="con_title">
      <!--            <span class="text_family">享受付出享受收获</span>-->
      <h4 class="text_family text_color">从业人员平均薪资</h4>
      <img src="../../assets/images/btzst.png" alt />
    </div>
    <!--   平均薪资 -->
    <div class="averageSalary">
      <ul class="flex_style alary_list direction_row flex_nowrap">
        <li class="first_li flex_style bounce">
          <span class="letter">
            我们秉持初心
            <br />做好教育，回馈社会和父母的信任
          </span>
          <p class="letter">
            积云
            <i>
              5.0 课程体系,
              <br />
            </i>
            专为学子 <i>量身</i>打造。
          </p>
        </li>
        <li class="flex_style border_left bounce">
          <span class="letter">{{
            moneylist[0] ? moneylist[0]["title"] : ""
          }}</span>
          <p class="letter">
            {{ moneylist[0] ? moneylist[0]["extend"]["subtitle"] : "" }}
          </p>
        </li>
        <li class="flex_style border_left bounce">
          <span class="letter">{{
            moneylist[1] ? moneylist[1]["title"] : ""
          }}</span>
          <p class="letter">
            {{ moneylist[1] ? moneylist[1]["extend"]["subtitle"] : "" }}
          </p>
        </li>
      </ul>
      <ul class="flex_style alary_list">
        <li class="flex_style bounce">
          <span class="letter">{{
            moneylist[2] ? moneylist[2]["title"] : ""
          }}</span>
          <p class="letter">
            {{ moneylist[2] ? moneylist[2]["extend"]["subtitle"] : "" }}
          </p>
        </li>
        <li class="flex_style border_left bounce">
          <span class="letter">{{
            moneylist[3] ? moneylist[3]["title"] : ""
          }}</span>
          <p class="letter">
            {{ moneylist[3] ? moneylist[3]["extend"]["subtitle"] : "" }}
          </p>
        </li>
        <li class="flex_style border_left bounce">
          <span class="letter">{{
            moneylist[4] ? moneylist[4]["title"] : ""
          }}</span>
          <p class="letter">
            {{ moneylist[4] ? moneylist[4]["extend"]["subtitle"] : "" }}
          </p>
        </li>
        <li class="flex_style border_left last_li bounce">
          <span class="letter">未来</span>
          <p class="letter">秉持初心，继续...</p>
          <!-- <div class="flex_style direction_row flex_nowrap align_center" @click='checkVideoFun(require("../../assets/video/money.mp4"))'>
                        <span>历年薪资</span>
                        <img src="../../assets/images/banner-bf.png" alt />
          </div>-->
        </li>
      </ul>
    </div>
    <!-- 学员作品-->
    <div class="con_title">
      <!--            <span class="text_family">在校即可得到业界最高标准</span>-->
      <h4 class="text_family text_color">学员作品</h4>
      <img src="../../assets/images/btzst.png" alt />
    </div>
    <div class="work">
      <div class="works_tex flex_style justify_center">
        <ul class="works_texList flex_style">
          <!--                    <li :class="{works_texList_color:categoryIndex==idx}" @click="clickcategory(idx)" v-for="(item,idx) in menu"-->
          <!--                        :key="idx">-->
          <!--                        {{item}}-->
          <!--                    </li>-->
          <!--                    <li>所有</li>-->
          <!--                    <li>/</li>-->
          <!--                    <li>PC项目</li>-->
          <!--                    <li>/</li>-->
          <!--                    <li>移动应用</li>-->
          <!--                    <li>/</li>-->
          <!--                    <li>插画设计</li>-->
          <!--                    <li>/</li>-->
          <!--                    <li>C4D设计</li>-->
          <!--                    <li>/</li>-->
          <!--                    <li>商业平面</li>-->
          <!--                    <li>/</li>-->
          <!--                    <li>其他</li>-->
        </ul>
      </div>
      <div class="works_con">
        <div class="works_conDouble flex_style justify_between">
          <div class="works_conDoubleLi">
            <div class="works_conDoubleLiImg">
              <img
                :src="
                  workslist[0]
                    ? workslist[0]['imageThumb']
                      ? workslist[0]['imageThumb']
                      : workslist[0]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[0] ? workslist[0]['image'] : '')"
              />
            </div>
            <div
              class="works_conDoubleLi_tex flex_style flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[0] ? workslist[0]["title"] : "" }}</p>
              <span>{{
                workslist[0] ? workslist[0]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
          <div class="works_conDoubleRi">
            <div class="works_conDoubleRi_img">
              <img
                :src="
                  workslist[1]
                    ? workslist[1]['imageThumb']
                      ? workslist[1]['imageThumb']
                      : workslist[1]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[1] ? workslist[1]['image'] : '')"
              />
            </div>
            <div
              class="works_conDoubleRi_tex flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[1] ? workslist[1]["title"] : "" }}</p>
              <span>{{
                workslist[1] ? workslist[1]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="works_conThree flex_style justify_between">
          <div class="works_conThreeBox">
            <div class="works_conThreeImg">
              <img
                :src="
                  workslist[2]
                    ? workslist[2]['imageThumb']
                      ? workslist[2]['imageThumb']
                      : workslist[2]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[2] ? workslist[2]['image'] : '')"
              />
            </div>
            <div
              class="works_conThreeTex flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[2] ? workslist[2]["title"] : "" }}</p>
              <span>{{
                workslist[2] ? workslist[2]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
          <div class="works_conThreeBox">
            <div class="works_conThreeImg">
              <img
                :src="
                  workslist[3]
                    ? workslist[3]['imageThumb']
                      ? workslist[3]['imageThumb']
                      : workslist[3]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[3] ? workslist[3]['image'] : '')"
              />
            </div>
            <div
              class="works_conThreeTex flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[3] ? workslist[3]["title"] : "" }}</p>
              <span>{{
                workslist[3] ? workslist[3]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
          <div class="works_conThreeBox">
            <div class="works_conThreeImg">
              <img
                :src="
                  workslist[4]
                    ? workslist[4]['imageThumb']
                      ? workslist[4]['imageThumb']
                      : workslist[4]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[4] ? workslist[4]['image'] : '')"
              />
            </div>
            <div
              class="works_conThreeTex flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[4] ? workslist[4]["title"] : "" }}</p>
              <span>{{
                workslist[4] ? workslist[4]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
        </div>
        <div class="works_conDouble flex_style justify_between">
          <div class="works_conDoubleLi">
            <div class="works_conDoubleLiImg">
              <img
                :src="
                  workslist[5]
                    ? workslist[5]['imageThumb']
                      ? workslist[5]['imageThumb']
                      : workslist[5]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[5] ? workslist[5]['image'] : '')"
              />
            </div>
            <div
              class="works_conDoubleLi_tex flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[5] ? workslist[5]["title"] : "" }}</p>
              <span>{{
                workslist[5] ? workslist[5]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
          <div class="works_conDoubleRi">
            <div class="works_conDoubleRi_img">
              <img
                :src="
                  workslist[6]
                    ? workslist[6]['imageThumb']
                      ? workslist[6]['imageThumb']
                      : workslist[6]['image']
                    : ''
                "
                alt
                @click="checkimgFun(workslist[6] ? workslist[6]['image'] : '')"
              />
            </div>
            <div
              class="works_conDoubleRi_tex flex_style direction_column flex_nowrap justify_around align_flex-start"
            >
              <p>{{ workslist[6] ? workslist[6]["title"] : "" }}</p>
              <span>{{
                workslist[6] ? workslist[6]["extend"]["subtitle"] : ""
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- 就业方向-->
      <div class="con_title">
        <!--                <span class="text_family">就业方向</span>-->
        <h4 class="text_family text_color">就业方向</h4>
        <img src="../../assets/images/btzst.png" alt />
      </div>
      <div class="service_list">
        <ul class="flex_style direction_row flex_nowrap justify_between">
          <li
            class="flex_style direction_column flex_nowrap justify_center align_center"
          >
            <img src="../../assets/images/cbatb.png" alt />
            <h5>UI设计师</h5>
            <p>专注于移动互联网设计方向，移动端 app设计领域的研究</p>
            <!--                        <span>阅读更多…</span>-->
          </li>
          <li
            class="flex_style direction_column flex_nowrap justify_center align_center"
          >
            <img src="../../assets/images/jqxxtb.png" alt />
            <h5>平面视觉设计师</h5>
            <p>满足于新型线下企业设计需求，广告传播 行业的设计人才培养</p>
            <!--                        <span>阅读更多…</span>-->
          </li>
          <li
            class="flex_style direction_column flex_nowrap justify_center align_center"
          >
            <img src="../../assets/images/jyxytb.png" alt />
            <h5>短视频运营</h5>
            <p>满足于当前迅猛发展的短视频需求 市场 ，带货短视频运营</p>
            <!--                        <span>阅读更多…</span>-->
          </li>
        </ul>
        <ul class="flex_style direction_row flex_nowrap justify_between">
          <li
            class="flex_style direction_column flex_nowrap justify_center align_center"
          >
            <img src="../../assets/images/gxjytb.png" alt />
            <h5>影视后期</h5>
            <p>
              网络大电影的爆发催生了众多影视后期 需求，就业渠道和薪水节节攀高
            </p>
            <!--                        <span>阅读更多…</span>-->
          </li>
          <li
            class="flex_style direction_column flex_nowrap justify_center align_center"
          >
            <img src="../../assets/images/jyfwtb.png" alt />
            <h5>MG动画师</h5>
            <p>
              未来虚拟现实交互越来越多;MG表达形式
              更能展现其内容传达的效率和视觉优势
            </p>
            <!--                        <span>阅读更多…</span>-->
          </li>
          <li
            class="flex_style direction_column flex_nowrap justify_center align_center"
          >
            <img src="../../assets/images/mfjxtb.png" alt />
            <h5>剪辑包装师</h5>
            <p>
              电影、电视的拍摄、大量广告、宣传剪辑师职
              位出现了短暂的供应不足现象片
            </p>
            <!--                        <span>阅读更多…</span>-->
          </li>
        </ul>
      </div>
      <!-- 我们的感言-->
      <!--            <div class="con_title">-->
      <!--                <span class="text_family">感言</span>-->
      <!--                <h4 class="text_family text_color">我们的故事 <br />学员感言分享</h4>-->
      <!--                <img src="../../assets/images/btzst.png" alt="">-->
      <!--            </div>-->
      <!--            <div class="share">-->
      <!--                <ul class="flex_style direction_row flex_nowrap justify_between">-->
      <!--                    <li>-->
      <!--                        <div class="share_con text_left flex_style direction_column flex_nowrap justify_around">-->
      <!--                            <span class="text_family">{{recollectionslist[0]?recollectionslist[0]['title']:''}}</span>-->
      <!--                            <p class="text_family">{{recollectionslist[0]?recollectionslist[0]['extend']['intro']:''}}</p>-->
      <!--                            <div class="flex_style direction_row flex_nowrap align_center justify_between">-->
      <!--                                <img src="../../assets/images/gyxx.png" alt="">-->
      <!--                                <img src="../../assets/images/crimsonbf.png" alt="">-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                        <div class="share_author flex_style direction_row flex_nowrap align_center justify_between">-->
      <!--                            <img src="../../assets/images/rwtx1_03.png" alt="">-->
      <!--                            <div class="text_left">-->
      <!--                                <p class="text_family text_color">{{recollectionslist[0]?recollectionslist[0]['extend']['subtitle']:''}}</p>-->
      <!--                                <span class="text_family">{{recollectionslist[0]?recollectionslist[0]['extend']['tag']:''}}</span>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                        <div class="share_con text_left flex_style direction_column flex_nowrap justify_around">-->
      <!--                            <span class="text_family">{{recollectionslist[1]?recollectionslist[1]['title']:''}}</span>-->
      <!--                            <p class="text_family">{{recollectionslist[1]?recollectionslist[1]['extend']['intro']:''}}</p>-->
      <!--                            <div class="flex_style direction_row flex_nowrap align_center justify_between">-->
      <!--                                <img src="../../assets/images/gyxx.png" alt="">-->
      <!--                                <img src="../../assets/images/crimsonbf.png" alt="">-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                        <div class="share_author flex_style direction_row flex_nowrap align_center justify_between">-->
      <!--                            <img src="../../assets/images/rwtx1_03.png" alt="">-->
      <!--                            <div class="text_left">-->
      <!--                                <p class="text_family text_color">{{recollectionslist[1]?recollectionslist[1]['extend']['subtitle']:''}}</p>-->
      <!--                                <span class="text_family">{{recollectionslist[1]?recollectionslist[1]['extend']['tag']:''}}</span>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </li>-->
      <!--                    <li>-->
      <!--                        <div class="share_con text_left flex_style direction_column flex_nowrap justify_around">-->
      <!--                            <span class="text_family">{{recollectionslist[1]?recollectionslist[2]['title']:''}}</span>-->
      <!--                            <p class="text_family">{{recollectionslist[1]?recollectionslist[2]['extend']['intro']:''}}</p>-->
      <!--                            <div class="flex_style direction_row flex_nowrap align_center justify_between">-->
      <!--                                <img src="../../assets/images/gyxx.png" alt="">-->
      <!--                                <img src="../../assets/images/crimsonbf.png" alt="">-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                        <div class="share_author flex_style direction_row flex_nowrap align_center justify_between">-->
      <!--                            <img src="../../assets/images/rwtx1_03.png" alt="">-->
      <!--                            <div class="text_left">-->
      <!--                                <p class="text_family text_color">{{recollectionslist[1]?recollectionslist[2]['extend']['subtitle']:''}}</p>-->
      <!--                                <span class="text_family">{{recollectionslist[1]?recollectionslist[2]['extend']['tag']:''}}</span>-->
      <!--                            </div>-->
      <!--                        </div>-->
      <!--                    </li>-->
      <!--                </ul>-->
      <!--                <div class="switch">-->
      <!--                    <img src="../../assets/images/jtle_03.png" alt="">-->
      <!--                    <img src="../../assets/images/jtri_03.png" alt="">-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
    <!-- 底部-->
    <bottom_public></bottom_public>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.min.js";
import "swiper/css/swiper.min.css";
import bottom_public from "../../components/bottom_public/bottom_public";
import header_public from "../../components/header_public/header_public";

const axios = require("axios").default;

export default {
  metaInfo: {
    title: "积云教育视觉设计培训-视觉设计培训|视觉设计培训机构",
    meta: [
      {
        name: "description",
        content:
          "积云教育视觉设计培训，课程设置紧跟时代潮流，真正围绕就业开展IT培训的视觉设计培训机构。",
      },
      {
        name: "keywords",
        content: "视觉设计培训,视觉设计培训课程,视觉设计培训机构",
      },
    ],
  },
  created() {
    this.getvideolistinfo(); //获取积云影音信息
    this.getteacherlist(); //获取教师团队列表
    this.getschoolScience(); //校园环境
    this.getmoneylist(); //获取平均薪资
    this.getrecollectionslist(); //学员感言
    this.getworkslist(); //学员作品
    this.getmajorvideo(); //获取专业宣传视频
  },
  data() {
    return {
      menu: [
        "所有",
        "/",
        "PC项目",
        "/",
        "移动应用",
        "/",
        "插画设计",
        "/",
        "C4D设计",
        "/",
        "商业平面",
        "/",
        "其他",
      ],
      index: 0,
      categoryIndex: 0,
      videolistinfo: {},
      teacherlist: {},
      schoolScience: {},
      moneylist: {},
      recollectionslist: {},
      workslist: {},
      videoSrc: "",
      videoState: false,
      imgSrc: "",
      imgState: false,
      majorvideo: "",
      swiperTeacher: null,
    };
  },
  methods: {
    clickcategory(idx) {
      this.categoryIndex = idx;
    },
    //积云影音
    getvideolistinfo() {
      //api/block/getItemsByName?name=积云影音&major=视觉设计
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("积云影音") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.videolistinfo = res.data.data; //积云影音列表数据
        });
    },
    //获取专业教师
    getteacherlist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("教师团队") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.teacherlist = res.data.data; //教师团队列表数据
          this.$nextTick(() => {
            this.swiperTeacher && this.swiperTeacher.destroy();
            this.swiperTeacher = new Swiper(".teacher-con", {
              slidesPerView: 4,
              slidesPerGroup: 1,
              observer: true, //修改swiper自己或子元素时，自动初始化swiper
              observeParents: false, //修改swiper的父元素时，自动初始化swiper
              autoplay: {
                delay: 3000,
                disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
                stopOnLastSlide: false, // 在最后一页停止
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              navigation: {
                nextEl: ".teachers-switch-next",
                prevEl: ".teachers-switch-prev",
              },
              loop: true,
            });
          });
        });
    },
    getschoolScience() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("校园环境") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.schoolScience = res.data.data; //校园环境列表数据
        });
    },
    getmoneylist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("平均薪资") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.moneylist = res.data.data; //积云影音列表数据
        });
    },
    getrecollectionslist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("学员感言") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.recollectionslist = res.data.data; //积云影音列表数据
        });
    },
    getworkslist() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("学员作品") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.workslist = res.data.data; //积云影音列表数据
        });
    },
    getmajorvideo() {
      axios
        .get(
          "/api/block/getItemsByName?name=" +
            encodeURIComponent("专业宣传") +
            "&major=" +
            encodeURIComponent("视觉设计")
        )
        .then((res) => {
          this.majorvideo = res.data.data; //学员感言列表数据
        });
    },
    checkimgFun(src) {
      this.imgState = true;
      this.imgSrc = src;
    },
    checkVideoFun(src) {
      this.videoState = true;
      this.videoSrc = src;
    },
    masksCloseFun() {
      this.videoState = false;
      this.imgState = false;
    },
    // 轮播鼠标经过离开事件
    start(tag) {
      tag.autoplay.start();
    },
    stop(tag) {
      tag.autoplay.stop();
    },
  },
  components: {
    bottom_public,
    header_public,
  },
  mounted() {
    // 【注意2:】
    // 轮播图 · 初始化设定
    new Swiper(".value-swiper", {
      spaceBetween: 30,
      effect: "fade",
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: false, //修改swiper的父元素时，自动初始化swiper
      autoplay: {
        delay: 3000,
        disableOnInteraction: false, // 取消鼠标操作后的轮播暂停【无操作轮播继续】【参考链接1】
        stopOnLastSlide: false, // 在最后一页停止
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".value-button-next",
        prevEl: ".value-button-prev",
      },
    });
  },
};
</script>

<style lang="scss" scoped>
#header {
  // position: static;
  opacity: 1;
  background: linear-gradient(
    270deg,
    rgba(254, 85, 105, 1),
    rgba(254, 90, 107, 1),
    rgba(117, 4, 189, 1),
    rgba(228, 25, 121, 1)
  );
}

.meadia {
  background: url("~@/assets/images/bodybj_03.png");
  background-repeat: repeat-y;
  background-size: 100% 200px;
  -moz-background-size: 100% 200px;
  margin-top: 5.4vw;
}

.text_left {
  text-align: justify !important;
}

.text_family {
  font-family: PingFang;
}

.text_color {
  color: rgba(28, 26, 91, 1) !important;
}

.con_style {
  width: 62.5vw;
  margin: 0 auto;
}

.flex_style {
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

.direction_row {
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  -o-flex-direction: row;
  flex-direction: row;
}

.direction_column {
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  -o-flex-direction: column;
  flex-direction: column;
}

.flex_wrap {
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.flex_nowrap {
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  -o-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.align_flex-start {
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
}

.align_center {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
}

.align_end {
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
}

.justify_start {
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  -o-justify-content: flex-start;
  justify-content: flex-start;
}

.justify_end {
  -webkit-justify-content: flex-end;
  -moz-justify-content: flex-end;
  -ms-justify-content: flex-end;
  -o-justify-content: flex-end;
  justify-content: flex-end;
}

.justify_center {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  -o-justify-content: center;
  justify-content: center;
}

.justify_around {
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  -ms-justify-content: space-around;
  -o-justify-content: space-around;
  justify-content: space-around;
}

.justify_between {
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
  -ms-justify-content: space-between;
  -o-justify-content: space-between;
  justify-content: space-between;
}

.meadia-top {
  width: 100%;
  height: 31.77vw;
  background: url("~@/assets/images/banner.png") no-repeat top;
  background-size: 50% auto;
  -moz-background-size: 50% auto;
  background-position-x: 46.604vw;
  -ms-background-position-x: 46.604vw;
}

.meadia-top-con {
  width: 62.5vw;
  height: 100%;
  margin: 0 auto;
}

h1 {
  font-size: 3.75rem;
  font-weight: bold;
  color: rgba(255, 23, 56, 1);
}

.meadia-top-con p {
  width: 53%;
  line-height: 1.25vw;
  font-size: 1rem;
  font-weight: 500;
  color: rgba(149, 148, 154, 1);
}

.meadia-top-con ul {
  text-align: justify;
}

.meadia-top-con ul li {
  margin-bottom: 2.083vw;
}

.meadia-top-con ul li:last-child {
  margin-bottom: 0 !important;
}

.meadia-top-con ul li div {
  height: 2.864vw;
  padding: 0 1.302vw;
  margin-right: 1.5625vw;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.meadia-top-con ul li div img {
  width: 1.5375vw;
  height: 1.5375vw;
  margin-right: 0.52vw;
  vertical-align: middle;
}

.meadia-top-con span {
  font-size: 0.75rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(149, 148, 154, 1);
  vertical-align: middle;
}

.con_title {
  margin: 1.041vw 0 2.604vw;
}

.con_title img {
  width: 3.541vw;
  height: 0.7291vw;
  margin-top: 0.52vw;
}

.con_title h4 {
  font-size: 2.5rem;
  font-weight: 800;
  margin: 1.041vw 0;
}

.con_title span {
  font-size: 14px;
  font-weight: bold;
  color: rgba(102, 102, 102, 1);
}

.features ul {
  width: 100%;
}

.features ul li span {
  font-weight: bold;
  font-size: 1.25rem;
  color: #000;
}

.features ul li p {
  font-size: 0.75rem;
  line-height: 1.25vw;
  color: rgba(102, 102, 102, 1);
  margin-top: 1.041vw;
}

.features ul li img {
  width: 1.354vw;
  height: 1.3541vw;
  margin-right: 0.78vw;
}

.swiper-slide {
  height: 23.645vw;
  position: relative;
}

.video_box {
  width: 42.1875vw;
  height: 23.6458vw;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}

.video_box > img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.video_box div {
  width: 3.593vw;
  height: 3.593vw;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.video_box div > img {
  width: 100%;
  height: 100%;
}

.value-button-next,
.value-button-prev {
  font-size: 1.125rem !important;
  outline: none;
  top: 45% !important;
}

.value-button-next {
  right: -3.541vw !important;
  color: #537cfe !important;
}

.value-button-prev {
  color: rgb(224, 94, 98) !important;
  left: -3.541vw !important;
}

.value-button-next:after,
.value-button-prev:after {
  font-size: 3.125rem;
}

.video_text {
  width: 20.052vw;
  height: 15.364vw;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 8px 27px 0px rgba(131, 160, 142, 0.35);
  border-radius: 10px;
  position: absolute;
  top: 3.689vw;
  right: 0.564vw;
  text-align: justify;
  padding: 2.997vw 1.9958vw;
  box-sizing: border-box;
}

.video_text span {
  font-size: 0.9375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(149, 148, 154, 1);
}

.video_text p {
  line-height: 1.5625vw;
  font-size: 1.0625rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(1, 1, 1, 1);
}

.movies_video {
  width: 100%;
  height: 23.333vw; //448px
  margin-bottom: 5.208vw;
}

.movies_videoLe {
  width: 41.614vw; //799px
  height: 23.333vw; //448px
  position: relative;
}

.anniu,
.anniu_small,
.anniu_smallBo {
  width: 100%;
  height: 100%;
  /*background:rgba(0,0,0,.5);*/
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.anniu_small,
.anniu_smallBo {
  height: 11.64vw;
}

.anniu img {
  width: 5.9375vw !important; //114px
  height: 5.9375vw !important; //114px
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.movies_videoLe img,
.movies_videoRiTo img,
.movies_videoRiBo img {
  width: 100%;
  height: 100%;
}

.movies_decorate,
.movies_decorateSmall {
  position: absolute;
  bottom: 0.5vw;
  left: 0.833vw;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.2vw 0.4vw;
}

.movies_decorate span,
.movies_decorateSmall span {
  font-size: 0.75rem;
  line-height: 1.041vw;
  color: $usian-bg-color;
  /*background:rgba(38,38,38,.5);*/
  margin-bottom: 0.533vw;
}

.movies_decorate h4,
.movies_decorateSmall h4 {
  font-size: 1.75rem;
  font-weight: bold;
  color: $usian-bg-color;
}

.movies_decorateSmall h4 {
  font-size: 1.25rem;
}

.movies_decorate p,
.movies_decorateSmall p {
  font-size: 0.875rem;
  font-weight: bold;
  color: $usian-bg-color;
}

.anniu_small img,
.anniu_smallBo img {
  width: 1.718vw;
  height: 1.718vw;
  position: absolute;
  top: 1.041vw;
  right: 1.041vw;
}

.movies_videoRi {
  width: 20.765vw; //398.7px
  height: 100%;
  // position: relative;
}

.movies_videoRiTo,
.movies_videoRiBo {
  width: 100%;
  height: 11.604vw; //223.5px
  position: relative;
}

.scheme {
  height: 37.656vw;
  background: url("~@/assets/images/gxsj.png") no-repeat center center;
  background-size: 50% auto;
  margin-top: 7.5525vw;
}

.scheme ul {
  width: 27%;
  height: 92%;
}

.scheme ul li:nth-child(2) {
  margin: 3.08vw 0;
}

.scheme ul li div {
  text-align: justify;
}

.scheme ul li span {
  line-height: 1.5vw;
  font-size: 1.5rem;
  font-weight: 800;
  color: rgba(8, 15, 77, 1);
}

.scheme ul:first-child li p {
  text-align: right;
}

.scheme ul li p {
  line-height: 1.1458vw;
  margin-top: 0.52vw;
  font-size: 0.875rem;
  font-weight: 800;
  color: rgba(123, 126, 146, 1);
}

.scheme ul li img {
  width: 2.135vw;
  height: 2.135vw;
}

.margin-left {
  margin-left: 0.78125vw;
}

.margin-right {
  margin-right: 0.78125vw;
}

.code_quality {
  margin-bottom: 7.81vw;
}

.code_quality ul li {
  width: 33%;
}

.code_quality ul li img {
  width: 3.02vw;
  height: 2.76vw;
}

.code_quality ul li span {
  display: block;
  margin: 35px 0;
  font-size: 1.6875rem;
  font-weight: bold;
}

.code_quality ul li p {
  font-size: 1.4375rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(149, 148, 154, 1);
}

.offer_title {
  height: 7.239vw;
  margin-bottom: 50px;
}

.offer_title p {
  font-size: 2.75rem;
  font-family: PingFang SC;
  font-weight: 600;
}

.offer_swiper {
  width: 88%;
  height: 100%;
  margin: 0 !important;
}

.offer_swiperCon {
  width: 100% !important;
  height: 100% !important;
}

.offer_swiperCon ul li {
  width: 7.552vw;
  height: 7.239vw;
  text-align: justify;
  box-sizing: border-box;
  padding: 0 2.08rem;
  background-color: #ffffff;
}

.offer_swiperCon ul li i {
  font-size: 0.75rem;
  font-weight: 800;
  color: rgba(103, 107, 129, 1);
}

.offer_swiperCon ul li span {
  font-size: 3.125rem;
  font-family: Arial Black;
  font-weight: 400;
}

.offer_color {
  color: #ff4b36 !important;
}

.ri_number {
  margin-right: 25px;
  position: relative;
}

.ri_number span {
  font-size: 3.375rem;
  font-family: Arial;
  font-weight: bold;
}

.ri_number i {
  position: absolute;
  top: -8px;
  right: -20px;
  font-size: 1.625rem;
  font-family: Arial;
  font-weight: bold;
}

.le_text span {
  display: block;
  margin-bottom: 10px;
  font-size: 1.625rem;
  font-family: PingFang SC;
  font-weight: 600;
}

.le_text p {
  font-size: 1.125rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(102, 102, 102, 1);
}

.le_text ul {
  margin-top: 15px;
}

.le_text ul li {
  margin-bottom: 15px;
}

.le_text ul li img {
  width: 0.52vw;
  height: 0.468vw;
  margin-right: 5px;
}

.le_text ul li p {
  font-size: 0.875rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(91, 109, 255, 1);
}

.width_33 {
  width: 31%;
}

.load_animation {
  width: 62.5vw;
  height: 37.656vw;
  margin: 0 auto;
  background: url("~@/assets/images/jjfasj.png") no-repeat left;
  background-size: 50% 90%;
  -moz-background-size: 50% 90%;
}

.progress {
  width: 25.937vw;
}

.progress p {
  font-size: 2.75rem;
  font-weight: 800;
}

.progress ul {
  margin-top: 40px;
}

.progress ul li {
  margin-bottom: 40px;
}

.progress ul li i {
  font-size: 0.75rem;
  font-weight: 800;
  color: rgba(133, 131, 142, 1);
}

.progress_box {
  width: 25.937vw;
  height: 0.364vw;
  background: rgba(239, 241, 255, 1);
  border-radius: 3px;
}

.progress_box div {
  width: 60%;
  height: 100%;
  background: rgba(63, 63, 249, 1);
  opacity: 0.99;
  border-radius: 3px 0px 0px 3px;
  position: relative;
  margin-top: 10px;
}

.progress_box div p {
  width: 2.5vw;
  height: 1.822vw;
  font-size: 0.75rem;
  font-weight: 800;
  line-height: 26px;
  color: rgba(211, 217, 251, 1);
  background: url("~@/assets/images/jdls.png") no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: absolute;
  top: -38px;
  right: -24px;
  text-align: center;
}

.color_orange {
  width: 94% !important;
  background-color: #ff4a36 !important;
}

.color_orange p {
  background: url("~@/assets/images/jdjs.png") no-repeat !important;
}

.dark_blue {
  width: 82% !important;
  background-color: #080d44 !important;
}

.dark_blue p {
  background: url("~@/assets/images/jdsl.png") no-repeat !important;
}

.campus_ambientCon {
  width: 62.5vw;
  margin: 6.25vw auto;
}

.ambientCon_top {
  margin-bottom: 4.166vw;
}

.topImgCon_topRi,
.topImgCon_topLe {
  width: 9.375vw;
}

.ambientCon_top img,
.topImgCon_topLe div,
.topImgCon_topRi div {
  border-radius: 8px 8px 8px 0px;
}

.ambientCon_topTex,
.ambientCon_topImg,
.ambientCon_topImgCon {
  width: 20.312vw;
  height: 20.312vw;
  border-radius: 8px 8px 8px 0px;
}

.enlarge_img {
  overflow: hidden;
}

.enlarge_img img {
  transition: all 1.5s; /*图片放大过程的时间*/
  position: relative;
}

.enlarge_img img:hover {
  cursor: pointer;
  transform: scale(1.2); /*以y轴为中心旋转*/
}

.topImgCon_top div {
  cursor: pointer;
}

.topImgCon_top div:hover img {
  transform: scale(1.2); /*以y轴为中心旋转*/
}

.ambientCon_topTex {
  text-align: left;
}

.ambientCon_topTex h5 {
  font-size: 1.6rem;
  line-height: 4.166vw;
  font-family: PingFang SC;
  font-weight: 400;
  color: rgba(32, 32, 32, 1);
}

.ambientCon_topTex p {
  width: 77%;
  font-size: 1rem;
  line-height: 1.66vw;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}

.ambientCon_topImg img {
  width: 100%;
  height: 100%;
}

.topImgCon_top,
.topImgCon_bot {
  width: 100%;
  height: 9.375vw;
}

.topImgCon_topLe,
.topImgCon_topRi,
.topImgCon_bot,
.ambientCon_topImg {
  position: relative;
}

.ambientCon_topImg div {
  position: absolute;
  bottom: 1.041vw;
  left: 1.041vw;
  font-size: 1.5625vw;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: rgba(255, 255, 255, 1);
}

.topImgCon_topLe div,
.topImgCon_topRi div {
  width: 100%;
  height: 100%;
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 16vw;
  color: rgba(255, 255, 255, 1);
  background: rgba(0, 0, 0, 1);
  opacity: 0.36;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.topImgCon_bot p {
  font-size: 1.25rem;
  font-weight: 800;
  color: rgba(255, 255, 255, 1);
  position: absolute;
  bottom: 0.82vw;
  left: 0.729vw;
}

.topImgCon_top img {
  width: 100%;
  height: 100%;
}

.topImgCon_bot img {
  width: 100%;
  height: 100%;
}

.ambientCon_topTex h5 {
  font-weight: 800;
}

.bottom_text {
  width: 62.5vw;
  height: 18.75vw;
  margin: 0 auto 7.8125vw;
  position: relative;
}

.teacher-con {
  height: 19.8vw !important;
}

.teachers_box {
  width: 15.625vw !important;
  height: 15.625vw !important;
  position: relative;
  border-radius: 5px;
  margin-right: 0 !important;
}

.teachers_box a {
  display: inline-block;
  width: 92%;
  height: 100%;
  overflow: hidden;
}

.teachers_box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  transition: all 1.5s; /*图片放大过程的时间*/
  position: relative;
}

.teachers_box img:hover {
  cursor: pointer;
  transform: scale(1.1); /*以y轴为中心旋转*/
}

.teacher_team_con {
  width: 92%;
  height: 16%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 1.729vw;
  background-color: rgba(0, 0, 0, 0.5);
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.team_con_top span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 3px 5px 4px #000000;
}

.team_con_top img {
  width: 1.666vw !important;
  height: 1.666vw !important;
}

.team_con_bottom p {
  font-size: 1.35rem;
  font-weight: bold;
  color: #ffffff;
}

.team_con_bottom span {
  font-size: 0.875rem;
  font-weight: bold;
  color: #ffffff;
  line-height: 2vw;
}

.major-spit {
  --swiper-theme-color: #cacaca;
  --swiper-pagination-color: #e11e2c; /* 两种都可以 */
}

.averageSalary {
  width: 62.5vw;
  margin: 0 auto 6.25vw;
}

.averageSalary ul,
.averageSalary ul li {
  height: 14.604vw;
  cursor: pointer;
}

.averageSalary ul {
  border-left: none !important;
  border-top: none !important;
  border: 1px solid rgba(240, 240, 240, 1);
}

/*.last_li {*/
/*    position: relative;*/
/*}*/
/*.last_li img{*/
/*    width: 1.875vw;*/
/*    height: 1.875vw;*/
/*    position: absolute;*/
/*    top: 2.604vw;*/
/*    right: 1.302vw;*/
/*}*/
.alary_list li {
  width: 15.625vw;
  text-align: left;
  padding: 0 0.5vw;
  box-sizing: border-box;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  background: rgba(255, 255, 255, 1);
}

.alary_list li span {
  display: inline-block;
  margin-bottom: 1.5625vw;
  font-size: 0.875rem;
  font-weight: bold;
  color: rgba(215, 7, 47, 1);
  /*line-height: 7rem;*/
}

.alary_list li p {
  font-size: 1.5rem;
  font-family: PingFang SC;
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
}

.alary_list li i {
  color: #d7072f;
}

.averageSalary ul:last-child li > div {
  height: 2.604vw;
  background: #222025;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 0.72vw;
  cursor: pointer;
}

.averageSalary ul:last-child li > div > span {
  font-size: 1rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #ffffff;
  vertical-align: middle;
}

.averageSalary ul:last-child li > div > img {
  width: 1.4vw;
  height: 1.4vw;
  vertical-align: middle;
  margin: 0.15vw 0 0 0.2vw;
}

.averageSalary ul:last-child li:last-child span {
  margin-bottom: 0 !important;
}

.averageSalary ul:last-child li:last-child p {
  margin: 10px 0;
}

.first_li {
  background-color: transparent !important;
  padding-left: 4.08vw !important;
  width: 31.25vw !important;
}

.first_li span {
  display: inline-block;
  margin-bottom: 0 !important;
  color: #000000 !important;
}

.first_li p {
  color: #000000 !important;
  font-size: 3rem !important;
}

.border_left {
  border-left: 1px solid rgba(240, 240, 240, 1);
}

.work {
  width: 62.5vw;
  margin: 0 auto;
}

.works_con {
  margin-bottom: 7.8125vw;
}

.works_tex {
  height: 3.125vw;
  margin-bottom: 1.041vw;
}

.works_texList {
  width: 76%;
  align-items: center;
}

.works_texList li {
  flex: 1;
  font-size: 1rem;
  font-weight: bold;
  color: #777777;
  cursor: pointer;
}

.letter:nth-child(1) {
  animation-delay: 0s;
}

.letter:nth-child(2) {
  animation-delay: 0.0833333333s;
}

@keyframes bounce {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, -1em, 0);
  }
}

.bounce:hover .letter {
  animation: bounce 0.75s cubic-bezier(0.05, 0, 0.2, 1) infinite alternate;
  transform: translate3d(0, 0, 0);
  margin-top: 0.5em;
}

.works_texList_color {
  color: #000000 !important;
}

.works_conDoubleLi,
.works_conThreeBox {
  width: 19.473vw;
}

.works_conDoubleLiImg,
.works_conDoubleRi_img {
  width: 100%;
  height: 21.401vw;
}

.works_conDoubleLi img,
.works_conDoubleRi img,
.works_conThreeImg img {
  width: 100%;
  height: 100%;
}

.works_conDoubleLi_tex,
.works_conDoubleRi_tex,
.works_conThreeTex {
  height: 3.645vw;
}

.works_conDoubleLi_tex p,
.works_conDoubleRi_tex p,
.works_conThreeTex p {
  font-size: 1.375rem;
  font-weight: 800;
  color: rgba(22, 21, 26, 1);
}

.works_conDoubleLi_tex span,
.works_conDoubleRi_tex span,
.works_conThreeTex span {
  font-size: 0.937rem;
  font-weight: 800;
  color: rgba(22, 21, 26, 1);
  opacity: 0.5;
}

.works_conDoubleRi {
  width: 40.7975vw;
}

.works_conDouble,
.works_conThree {
  margin-bottom: 2.0833vw;
}

.service_list {
  margin-bottom: 7.8125vw;
}

.service_list ul {
  height: 16.041vw;
  margin-bottom: 3.645vw;
}

.service_list ul li {
  width: 18.229vw;
  height: 100%;
  box-shadow: 0px 12px 26px 1px rgba(206, 212, 239, 0.63);
  border-radius: 36px 0px 36px 0px;
}

.service_list ul li:hover {
  transform: translateY(-20px);
  transition: all 1s;
}

.service_list ul li img {
  width: 4.166vw;
  height: 3.4375vw;
}

.service_list ul li h5 {
  margin: 0.2vw 0 1vw;
  font-size: 1.43rem;
  font-weight: 800;
  color: rgba(51, 61, 88, 1);
}

.service_list ul li p {
  padding: 0 1.041vw;
  margin-bottom: 2vw;
  line-height: 1.3vw;
}

.service_list ul li p,
.service_list ul li span {
  font-size: 1.0625rem;
  font-weight: bold;
  color: rgba(121, 127, 137, 1);
}

.service_list ul li span {
  color: rgba(41, 44, 47, 1) !important;
}

.share ul li {
  width: 34%;
}

.share_con {
  width: 100%;
  height: 13.958vw;
  box-sizing: border-box;
  padding: 2.8125vw 2.8125vw 2.395vw 2.604vw;
  background: url("~@/assets/images/gyqpbj.png") no-repeat;
  background-size: cover;
  -moz-background-size: cover;
  margin-bottom: 0.781vw;
}

.share_con span {
  font-size: 1.4375rem;
  font-weight: bold;
  color: rgb(157, 169, 189);
}

.share_con p {
  font-size: 1rem;
  font-weight: 800;
  color: rgba(104, 104, 104, 1);
  line-height: 26px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.share_con div img:first-child {
  width: 6.041vw;
  height: 0.9375vw;
}

.share_con div img:last-child {
  width: 1.927vw;
  height: 1.927vw;
}

.share_author {
  /*width: 370px;*/
  width: 96%;
  box-sizing: border-box;
  padding: 0px 3.229vw 0px 1.875vw;
}

.share_author img {
  width: 3.645vw;
  height: 3.645vw;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.52vw;
}

.share_author p {
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.406vw;
}

.share_author span {
  font-size: 1rem;
  font-weight: 800;
  color: rgba(102, 102, 102, 1);
}

.switch {
  margin-top: 2.604vw;
}

.switch img {
  margin-right: 0.781vw;
  width: 2.604vw !important;
  height: 2.604vw !important;
}

.maska {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: #000000;
  opacity: 0.6;
}

.videomasks {
  max-width: 62vw;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
}

.videomasks video {
  width: 100%;
  height: 100%;
}

.imagemasks {
  max-width: 1000px;
  position: fixed;
  left: 50%;
  top: 58%;
  overflow: auto;
  height: 550px;
  z-index: 9999999;
  transform: translate(-50%, -50%);
}

.imagemasks::-webkit-scrollbar {
  display: none;
}

.imagemasks img {
  width: 100%;
}

@media screen and (max-width: 1222px) {
  .meadia-top-con ul li div {
    width: 9.8125vw;
  }
}

@media screen and (max-width: 1148px) {
  .explain_text {
    height: 15.6875vw;
  }
  .explainBottom {
    height: 12.354vw;
  }
}

@media screen and (max-width: 1048px) {
  .meadia-top-con ul li div {
    width: 10.2125vw;
  }
}

@media screen and (max-width: 1200px) {
  .meadia-top-con ul li > h1 {
    font-size: 3.55rem;
  }
}

@media screen and (max-width: 1125px) {
}

@media screen and (max-width: 1024px) {
}
</style>
